<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <b-row class="match-height">
              <b-col cols="12">
                <tab-position :tabs="tabs" :name="'Commission'" />
              </b-col>
            </b-row> -->
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search title="Commission" :moduleapi="`${this.baseApi}/commission`" :columns="columns"
                routerpath="/master/commission/addcommission" :newPage="true" :formOptions="formOptions" :edit="edit"
                :add="add" :canDelete="canDelete" :viewFields="viewFields" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TabPosition from "../../../../components/TabView.vue";
import GoodTableColumnSearch from "../../../../components/GoodTableColumnSearch.vue";

const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    GoodTableColumnSearch,
    TabPosition,
  },
  data() {
    return {
      // tabs: [
      //   { name: 'Lead Source', route: '/master/crm/leadsource', icon: 'CompassIcon' },
      //   { name: 'Lead Stage', route: '/master/crm/leadstage', icon: 'CommandIcon' },
      //   { name: 'Campaign Name', route: '/master/crm/campaign', icon: 'MinimizeIcon' },
      //   { name: 'Reason', route: '/master/crm/reason', icon: 'ApertureIcon' },
      //   { name: 'Channel Partner', route: '/master/crm/channelpartner', icon: 'UsersIcon' },
      //   { name: 'Franchise', route: '/master/crm/franchise', icon: 'FramerIcon' },
      //   { name: 'Project', route: '/master/crm/project', icon: 'PackageIcon' },
      //   { name: 'Plot', route: '/master/crm/plot', icon: 'MapIcon' },
      //   { name: 'Land', route: '/master/crm/land', icon: 'SlackIcon' },
      //   { name: 'Commission', route: '/master/crm/commission', icon: 'GitCommitIcon' },
      //   { name: 'Project Mapping', route: '/master/crm/projectmapping', icon: 'MapPinIcon' }
      // ],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      formOptions: {
        formTitle: `${this.$route.params.id ? "Edit Commission" : "Add Commission"}`,
        submitRouterPath: "/master/crm/commission",
        gridForm: true,
        method: "post",
        action: "add",
        url: `${baseApi}/commission`,
        getEditValue: `${baseApi}/getcommissionById`,
        inputFields: [],
      },
      viewFields: [
        {
          label: "Type",
          field: "type",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "For",
          field: "for",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "ducuments",
          field: "CommissionItem",
          tableHead: ["Rank", "Name", "From", "To", "Days", "Incentive"],
          tableField: [
            {
              type: "text",
              field: "rank",
            },
            {
              type: "text",
              field: "name",
            },
            {
              type: "text",
              field: "from",
            },
            {
              type: "text",
              field: "to",
            },
            {
              type: "text",
              field: "days",
            },
            {
              type: "text",
              field: "incentive",
            },
          ],
          type: "multiple",
          class: "col-md-12",
        },
      ],
      columns: [
        {
          label: "Type",
          field: "type",
          filterOptions: {
            enabled: true,
            placeholder: "Search Type",
          },
        },
        {
          label: "For",
          field: "for",
          filterOptions: {
            enabled: true,
            placeholder: "Search For",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",
          url: 'getUsers',

          responseValue: "fullname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "created_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          responseValue: "fullname",
          url: 'getUsers',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "updated_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        // {
        //   label: 'Type',
        //   field: 'type',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Search Type'
        //   }
        // },
        // {
        //   label: 'Name',
        //   field: 'name',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Search Name'
        //   }
        // },
        {
          label: "Action",
          field: "action",
        },
      ],
      edit: "",
      add: "",
      canDelete: "",
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Commission") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (item.edit !== 1 && item.add !== 1 && item.delete !== 1 && item.view !== 1) {
            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
